// import httpService from './../httpIntercept'
import {get, post } from './../httpIntercept'

export default {
	// 注册获取短信验证码
	getSms(params) {
		return get('api/v1/sdk/getVerify', params)
	},
	// 账号密码登录
	login(params) {
		return post('api/v1/sdk/login', params)
	},
	// 
	loginOut(params) {
		return get('api/v1/sdk/logout', params)
	},
	//修改用户信息
	updateManagementById(params) {
		return post('manageUser/updateManagementById', params)
	},
}
