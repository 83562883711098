// import httpService from './../httpIntercept'
import { post, get } from "./../httpIntercept";
export default {
  // 广告
  selectAds(params) {
    return get("basicInfo/selectAds", { params: params });
  },
  // 导航
  selectNavigation(params) {
    return get("basicInfo/selectNavigation", { params: params });
  },
  logoImage(params) {
    return get("file/downLoad", params);
  },

  getNewsColumnData(params) {
    // return post('newNews/getNewsColumnData', params)
    return post(`api/sprint/getStoryList`, params);
  },

  getSpecialSubjectData() {
    return post(
      process.env.VUE_APP_ADVERTISING + `site/thematicPictures/selectList`,
      {
        appInfoId: Number(process.env.VUE_APP_INFO_ID),
      }
    );
  },
  insertComment(params) {
    return post("userComment/insertComment", params);
  },
  getComment(params) {
    return post("userComment/selectUserNews", params);
  },
  isLikeStatus(params) {
    return post("userNewsCollection/likeStatus", params);
  },
  addLike(params) {
    return post("userNewsCollection/like", params);
  },

  getDownloadNumByTime(){
    return get('api/info/getDownloadNumByTime')
  },
  getTypeNum(){
    return get('api/info/getTypeNum')
  },
  getUserNum(){
    return get('api/info/getUserNum')
  },
  getHotArticle(){
    return get('api/info/getHotArticle')
  },
  getHotUserByType(data){
    return post('api/info/getHotUserByType',data)
  },
  getNewslist(data){
    return post('api/v1/sprint/getStoryList',data)
  },
};
